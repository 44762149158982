import React from 'react';
import { Filter, ReferenceInput, SelectInput } from 'react-admin';

const optionRenderer = choice => `${choice.init_at_format} - ${choice.live_course_class.name}`;
const CustomFilterLiveCourseClassDate = (props) => (
  <Filter {...props}>
    <ReferenceInput sort={{ field: 'init_at', order: 'DESC' }} perPage={1000} allowEmpty={false} source="live_course_class_date_id" label="Aula" reference="livecourseclassdate" alwaysOn >
      <SelectInput optionText={optionRenderer} optionValue="id" />
    </ReferenceInput>
  </Filter>
);

export default CustomFilterLiveCourseClassDate;
