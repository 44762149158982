import React from "react";
import { List, Datagrid, TextField } from "react-admin";

// own ui
import CustomFilterSingleClassDate from "../../ui/custom-filter-single-class-date";
import SingleClassDateReserveExporter from "../../exporter/single-class-date-reserve-exporter";
import ApproveButtonSingleClassDate from "../../ui/approve-button-single-class-date";
import ReproveButtonSingleClassDate from "../../ui/reprove-button-single-class-date";
import rowReserveStyle from "../../ui/row-reserve-style";
import BirthdayCustomField from "../../ui/birthday-custom-field";
import CoinsCustomField from "../../ui/coins-custom-field";

export default (props) => (
  <List
    title="Alunos"
    {...props}
    sort={{ field: "profile.name", order: "ASC" }}
    filters={<CustomFilterSingleClassDate />}
    bulkActionButtons={false}
    exporter={SingleClassDateReserveExporter}
  >
    <Datagrid rowStyle={rowReserveStyle()}>
      <TextField source="id" sortable={false} />
      <TextField
        source="status_reserve_single_class_date_name"
        label="Status"
        sortable={false}
      />
      <TextField
        source="profile.name"
        label="Nome da Criança"
        sortable={true}
      />
      <BirthdayCustomField source="profile.birthday" label="Aniversário" />
      <TextField
        source="profile.coins"
        label="Coins da Criança"
        sortable={false}
      />
      <TextField source="single_class_name" label="Aula" sortable={false} />
      <TextField source="init_at_format" label="Data" sortable={false} />
      <CoinsCustomField sortable={false} />
      <ApproveButtonSingleClassDate />
      <ReproveButtonSingleClassDate />
    </Datagrid>
  </List>
);
