import React from "react";
import { List, Datagrid, TextField } from "react-admin";

// own ui
import CustomFilterLiveCourseClassDate from "../../ui/custom-filter-live-course-class-date";
import LiveCourseClassDateReserveExporter from "../../exporter/live-course-class-date-reserve-exporter";
import ApproveButton from "../../ui/approve-button";
import ReproveButton from "../../ui/reprove-button";
import rowReserveStyle from "../../ui/row-reserve-style";
import BirthdayCustomField from "../../ui/birthday-custom-field";
import CoinsCustomField from "../../ui/coins-custom-field";

export default (props) => {
  return (
    <List
      title="Alunos"
      {...props}
      sort={{ field: "profile.name", order: "ASC" }}
      filters={<CustomFilterLiveCourseClassDate />}
      bulkActionButtons={false}
      exporter={LiveCourseClassDateReserveExporter}
    >
      <Datagrid rowStyle={rowReserveStyle()}>
        <TextField source="id" sortable={false} />
        <TextField
          source="status_reserve_class_date_name"
          label="Status"
          sortable={false}
        />
        <TextField
          source="profile.name"
          label="Nome da Criança"
          sortable={true}
        />
        <BirthdayCustomField source="profile.birthday" label="Aniversário" />
        <TextField
          source="profile.coins"
          label="Coins da Criança"
          sortable={false}
        />
        <TextField
          source="live_course_class_name"
          label="Aula"
          sortable={false}
        />
        <TextField
          source="init_at_complete_format"
          label="Data"
          sortable={false}
        />
        <ApproveButton />
        <ReproveButton />
        <CoinsCustomField sortable={false} />
      </Datagrid>
    </List>
  );
};
