import React from 'react';
import get from 'lodash/get';

const styles = {
    main: {
        display: 'flex',
        flexWrap: 'wrap',
    },
};

const LinkField = ({ record, source, ...props }) => {
    const value = get(record, source);

    return (
        <span style={styles.main}>
        {
          <a
            href={value}
            target="_blank"
            rel="noopener noreferrer"
            onClick={ (e) => { e.stopPropagation() }}
          >{props.text}</a>
        }
        </span>
    );
};

LinkField.defaultProps = {
    addLabel: true,
    source: 'Link'
};

export default LinkField;
