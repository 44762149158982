import { stringify } from 'query-string';
import axios from 'axios';
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  UPDATE,
  CREATE,
  HttpError
} from 'react-admin';

import config from '../config.json';
import paramsToFormData from '../utils/paramsToFormData';

const ENV = process.env.NODE_ENV || 'development';
var jwt = require('jwt-simple');

const apiUrl = config[ENV].api.url;
const apikey = config[ENV].api.api_key;
const jwtKey = config[ENV].jwt.key;
const localStorageUser = config[ENV].local_storage.user;

/**
 * Maps react-admin queries to my REST API
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
const provider = (type, resource, params) => {
  let url = '';

  const token = JSON.parse(jwt.decode(localStorage.getItem(jwt.encode(localStorageUser, jwtKey)), jwtKey)).auth_token;
  const options = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + token
    },
    data: paramsToFormData(params.data)
  };

  switch (type)
  {
    case GET_LIST:
    {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([
          (page - 1) * perPage,
          page * perPage - 1,
        ]),
        filter: JSON.stringify(params.filter),
        apikey: apikey
      };

      if (resource === 'ChatDenounce')
        url = `${apiUrl}/chat/denounces?${stringify(query)}`;
      else if (resource === 'ChatBan')
        url = `${apiUrl}/chat/banned?${stringify(query)}`;
      else if (resource === 'PromotionSubscribers')
        url = `${apiUrl}/promotion/${params.filter.promotion_id ? params.filter.promotion_id : 0}/subscribers?${stringify(query)}`;
      else
        url = `${apiUrl}/${resource}?${stringify(query)}`;

      options.url = url;

      break;
    }
    case GET_ONE:
    {
      const query = {
        apikey: apikey
      };

      url = `${apiUrl}/${resource}/${params.id}?${stringify(query)}`;
      options.url = url;

      break;
    }
    case GET_MANY:
    {
      const query = {
        apikey: apikey
      };

      url = `${apiUrl}/${resource}/?${stringify(query)}`;
      options.url = url;

      break;
    }
    case GET_MANY_REFERENCE:
    {
      if (resource === 'promotion/subscribers')
      {
        const query = {
          apikey: apikey
        };

        url = `${apiUrl}/promotion/${params.id}/subscribers?${stringify(query)}`;
      }
      else if (resource === 'survey/participants')
      {
        const query = {
          apikey: apikey
        };

        url = `${apiUrl}/survey/${params.id}/participants?${stringify(query)}`;
      }
      else if (resource === 'promotionquiz/ranking')
      {
        const query = {
          apikey: apikey
        };

        url = `${apiUrl}/promotionquiz/${params.id}/ranking?${stringify(query)}`;
      }
      else if (resource === 'chat/denounce/status')
      {
        const query = {
          apikey: apikey
        };

        url = `${apiUrl}/chat/denounce/status?${stringify(query)}`;
      }
      else
      {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            apikey: apikey,
            range: JSON.stringify([
                (page - 1) * perPage,
                page * perPage - 1,
            ]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };

        url = `${apiUrl}/${resource}?${stringify(query)}`;
      }
      options.url = url;

      break;
    }
    case UPDATE:
    {
      const query = {
        apikey: apikey
      };

      if (resource === 'ChatDenounce')
        url = `${apiUrl}/chat/denounce/${params.id}?${stringify(query)}`;
      else if (resource === 'ChatBan')
        url = `${apiUrl}/chat/ban/${params.id}?${stringify(query)}`;
      else
        url = `${apiUrl}/${resource}/${params.id}?${stringify(query)}`;

      options.method = 'PUT';
      options.url = url;

      break;
    }
    case CREATE:
    {
      const query = {
        apikey: apikey
      };

      url = `${apiUrl}/${resource}?${stringify(query)}`;
      options.method = 'POST';
      options.url = url;

      break;
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }

  return axios(options)
    .then(res =>
    {
      return res.data;
    })
    .then(response =>
    {
      switch (type)
      {
        case GET_LIST:
        case GET_MANY:
          const data = response.data;
          const meta = response.meta;
          return {
            data: data,
            total: meta.total,
            pagination:
            {
              page: meta.page,
              perPage: meta.perpage
            }
          }
        case GET_MANY_REFERENCE:
          return {
            data: response.result,
            total: response.result.lenght
          }
        case GET_ONE:
          return {
            data: response.result
          }
        case UPDATE:
        case CREATE:
          return {
            data: response.result
          }
        default:
          return { data: [] }
      }
    }
  )
  .catch(error =>
  {
    if (error.response.status < 200 || error.response.status >= 300)
    {
      if (error.response.data && error.response.data.error)
      {
        return Promise.reject(
          new HttpError(error.response.data.error, error.response.status)
        );
      }
      else
      {
        return Promise.reject(
          new HttpError(error.response.statusText, error.response.status)
        );
      }
    }
  });
};

export default provider;
