import * as React from "react";
import { HiOutlineCake } from "react-icons/hi";

const verifyBirthDay = (birthday) => {
  var today = new Date();
  today = new Date(today.setHours(0));
  today = new Date(today.setMinutes(0));
  today = new Date(today.setSeconds(0));
  today = new Date(today.setMilliseconds(0));

  const sunday = new Date(today.setDate(today.getDate() - today.getDay()));
  const saturday = new Date(
    today.setDate(today.getDate() + (6 - today.getDay()))
  );

  var dateParts = birthday.split("/");
  var dateObject = new Date(
    today.getFullYear(),
    dateParts[1] - 1,
    +dateParts[0]
  );

  if (
    dateObject.getTime() >= sunday.getTime() &&
    dateObject.getTime() <= saturday.getTime()
  ) {
    return true;
  } else {
    return false;
  }
};

const birthStyle = (isYouBirthday) => {
  if (isYouBirthday)
    return {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      columnGap: "4px",

      backgroundColor: "#004DC9",
      padding: "8px",
      color: "#fff",
      borderRadius: "4px",
    };
  else return {};
};

const formatDate = (birthday) => {
  var dateParts = birthday.split("/");
  return dateParts[0] + "/" + dateParts[1];
};

export default ({ source, record = {} }) => {
  return (
    <div style={birthStyle(verifyBirthDay(record.profile.birthday))}>
      {verifyBirthDay(record.profile.birthday) ? (
        <HiOutlineCake size={20} color="#fff" />
      ) : (
        ""
      )}
      {formatDate(record.profile.birthday)}
      {verifyBirthDay(record.profile.birthday) ? (
        <HiOutlineCake size={20} color="#fff" />
      ) : (
        ""
      )}
    </div>
  );
};
