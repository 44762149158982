import React from 'react';
import { useMutation, useNotify, Button } from 'react-admin';


const ApproveButton = ({ record }) => {
    const notify = useNotify();
    const [mutate, { loading }] = useMutation();
    const approve = () => mutate({
        type: 'update',
        resource: 'livecourseclassdatereserve',
        payload: {
            id: record.id,
            data: { status_reserve_class_date: 1 }
        },
      },
      {
          onFailure: (error) => notify(`${error.message}`, 'warning'),
      }
    );

    const handleConfirmate = () => {
        if(window.confirm("Deseja confirmar a presença do aluno ?")){
            approve();
        }
    }

    if (record.status_reserve_class_date === 0)
        return <Button
                 label="Assistiu"
                 onClick={handleConfirmate}
                 disabled={loading}
               />
    else
        return null;
};

export default ApproveButton;
