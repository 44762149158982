import * as React from "react";
import { useMutation, useNotify, Button } from "react-admin";

const getCoins = () => {
  let numberOfCoins = window.prompt(
    "Qual a quantidade de coins adicionais, o aluno deve receber ? \n * Somente números são permitidos"
  );

  if (numberOfCoins === null) return null;

  if (isNaN(numberOfCoins) || numberOfCoins <= 0) {
    window.alert("Por favor insira um valor valido para as coins.");
    return;
  }
  return numberOfCoins;
};

export default ({ source, record = {} }) => {
  const notify = useNotify();
  const [mutate, { loading }] = useMutation();

  const addCoins = () => {
    const amountCoins = getCoins();
    if (amountCoins === null) return;
    const profileId = record.profile.id;
    const reserveId = record.id;
    mutate(
      {
        type: "update",
        resource: "coins/teacherbonusadd",
        payload: {
          id: reserveId,
          data: {
            profileId: profileId,
            amount: amountCoins,
          },
        },
      },
      {
        onSuccess: () => {
          document.location.reload(true);
        },

        onFailure: (error) => notify(`${error.message}`, "warning"),
      }
    );
  };

  if (
    record.status_reserve_class_date === 1 ||
    record.status_reserve_single_class_date === 1
  )
    return (
      <Button
        label="Coins Bônus"
        onClick={addCoins}
        disabled={loading}
      ></Button>
    );
  else return null;
};
