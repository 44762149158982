import React from 'react';
import {
    List,
    Datagrid,
    TextField
} from 'react-admin';

// own ui
import CustomFilterText from '../../ui/custom-filter-text';
import LiveCourseClassDateExporter from '../../exporter/live-course-class-date-exporter';
import LinkField from '../../ui/link-field';
import LinkReservesField from '../../ui/link-reserves';

export default props => (
    <List title="Minhas Aulas" {...props} sort={{ field: 'lccd.init_at', order: 'ASC' }} filters={<CustomFilterText />} bulkActionButtons={false} exporter={LiveCourseClassDateExporter}>
      <Datagrid>
        <TextField source="id" sortable={false} />
        <TextField source="init_at_format" label="Início" sortable={false}/>
        <TextField source="end_at_format" label="Término" sortable={false}/>
        <TextField source="live_course_class.name" label="Aula" sortable={false}/>
        <TextField source="class_group_name" label="Turma" sortable={false}/>
        <LinkField source="live_course_class.live_course_class_plan.secure_url_file" text="Ver" label="Plano de Aula" sortable={false}/>
        <LinkField source="link" label="Link" text="Ir" sortable={false}/>
        <TextField source="number_participants" label="Número de Participantes" sortable={false}/>
        <LinkReservesField label="Número de Reservas" sortable={false}/>
      </Datagrid>
    </List>
);
