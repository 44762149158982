import React from 'react';
import { Filter, ReferenceInput, SelectInput } from 'react-admin';

const optionRenderer = choice => `${choice.init_at_format} - ${choice.single_class.name}`;
const CustomFilterSingleClassDate = (props) => (
  <Filter {...props}>
    <ReferenceInput sort={{ field: 'init_at', order: 'DESC' }} perPage={1000} allowEmpty={false} source="single_class_date_id" label="Aula Avulsa" reference="singleclassdate" alwaysOn >
      <SelectInput optionText={optionRenderer} optionValue="id" />
    </ReferenceInput>
  </Filter>
);

export default CustomFilterSingleClassDate;
