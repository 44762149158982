import * as React from "react";
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink, DashboardMenuItem, usePermissions } from 'react-admin';
import SubMenu from './submenu';

// ICONS
import LiveIcon from '@material-ui/icons/LiveTv';
import BoardIcon from '@material-ui/icons/AssignmentInd';
import ClassIcon from '@material-ui/icons/Class';
import UsersIcon from '@material-ui/icons/ChildCare';

const Menu: FC<Props> = ({ onMenuClick, dense }) => {
    const [state, setState] = useState({
        menuLiveCourse: false,
        menuSingleClass: false
    });

    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme);

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    const { loaded } = usePermissions();

    return loaded ? (
        <div>
          <br />
          <DashboardMenuItem
            sidebarIsOpen={open} />
          <SubMenu
              handleToggle={() => handleToggle('menuLiveCourse')}
              isOpen={state.menuLiveCourse}
              sidebarIsOpen={open}
              sidebarInside={false}
              name="Kidsa Live"
              icon={<LiveIcon />}
              dense={dense}
          >
            <MenuItemLink
              to="/livecourseclassdate"
              primaryText="Listar"
              leftIcon={<ClassIcon />}
              onClick={onMenuClick} />
            <MenuItemLink
              to="/livecourseclassdatereserve"
              primaryText="Alunos"
              leftIcon={<UsersIcon />}
              onClick={onMenuClick} />
          </SubMenu>
          <SubMenu
              handleToggle={() => handleToggle('menuSingleClass')}
              isOpen={state.menuSingleClass}
              sidebarIsOpen={open}
              sidebarInside={false}
              name="Aula Avulsa"
              icon={<BoardIcon />}
              dense={dense}
          >
            <MenuItemLink
              to="/singleclassdate"
              primaryText="Listar"
              leftIcon={<ClassIcon />}
              onClick={onMenuClick} />
            <MenuItemLink
              to="/singleclassdatereserve"
              primaryText="Alunos"
              leftIcon={<UsersIcon />}
              onClick={onMenuClick} />
          </SubMenu>
        </div>
    ): null;
}

export default Menu;
