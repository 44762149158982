const isUndefined = value => value === undefined;
const isNull = value => value === null;
const isObject = value => value === Object(value);
const isArray = value => Array.isArray(value);
const isDate = value => value instanceof Date;

const isFile = value =>
  value &&
  value.rawFile != null &&
  value.rawFile instanceof File;

const isBlob = value =>
  value &&
  typeof value.size === 'number' &&
  typeof value.type === 'string' &&
  typeof value.slice === 'function';

const paramsToFormData = (obj, cfg, fd, pre) =>
{
  cfg = cfg || {};
  cfg.indices = isUndefined(cfg.indices) ? false : cfg.indices;
  cfg.nullsAsUndefineds = isUndefined(cfg.nullsAsUndefineds) ? false : cfg.nullsAsUndefineds;
  fd = fd || new FormData();

  if (isUndefined(obj))
    return fd;
  else if (isNull(obj))
  {
    if (!cfg.nullsAsUndefineds)
      fd.append(pre, '');
  }
  else if (isArray(obj))
  {
    if (!obj.length)
      fd.append((pre + '[]'), '');
    else
    {
      obj.forEach((value, index) =>
      {
        const key = pre + '[' + index + ']';
        paramsToFormData(value, cfg, fd, key);
      });
    }
  }
  else if (isDate(obj))
    fd.append(pre, obj.toISOString());
  else if (isObject(obj) && !isFile(obj) && !isBlob(obj))
  {
    Object.keys(obj).forEach(prop =>
    {
      const value = obj[prop];
      if (isArray(value))
      {
        while (prop.length > 2 && prop.lastIndexOf('[]') === prop.length - 2)
          prop = prop.substring(0, prop.length - 2);
      }

      const key = pre ? pre + '[' + prop + ']' : prop;
      paramsToFormData(value, cfg, fd, key);
    });
  }
  else if (isFile(obj))
    fd.append(pre, obj.rawFile);
  else
    fd.append(pre, obj);

  return fd;
}

export default paramsToFormData;
