import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = reserves => {
    const data = reserves.map(reserve => ({
        status: reserve.status_reserve_class_date_name,
        nome_filho: reserve.profile.name,
        nome_pai: reserve.profile.customer_name,
        email_pai: reserve.profile.customer_email
    }));
    const csv = convertToCSV({
        data,
        fields: ['status', 'nome_filho', 'nome_pai', 'email_pai']
    });
    downloadCSV(csv, 'live_course_class_reserves');
}

export default exporter;
