import React from 'react';
import { Admin, Resource } from 'react-admin';

import providers from './provider';

import Dashboard from './physis/dashboard';
import LoginPage from './auth/LoginPage';
import Layout from './layout/layout';

// LIVE COURSE CLASS DATE
import LiveCourseClassDateIcon from '@material-ui/icons/Class';
import LiveCourseClassDateList from './container/livecourseclassdate/list';

// LIVE COURSE CLASS DATE RESERVE
import LiveCourseClassDateReserveIcon from '@material-ui/icons/ChildCare';
import LiveCourseClassDateReserveList from './container/livecourseclassdatereserve/list';

// SINGLE CLASS DATE
import SingleClassDateIcon from '@material-ui/icons/Class';
import SingleClassDateList from './container/singleclassdate/list';

// SINGLE CLASS DATE RESERVE
import SingleClassDateReserveIcon from '@material-ui/icons/ChildCare';
import SingleClassDateReserveList from './container/singleclassdatereserve/list';

import polyglotI18nProvider from 'ra-i18n-polyglot';
import portugueseMessages from 'ra-language-portuguese';

const i18nProvider = polyglotI18nProvider(() => portugueseMessages, 'pt');

const App = () => (
  <Admin
    title="Kidsa Live - Teacher Admin"
    i18nProvider={i18nProvider}
    locale="pt"
    loginPage={LoginPage}
    dashboard={Dashboard}
    layout={Layout}
    {...providers}>

      <Resource
        icon = { LiveCourseClassDateIcon }
        name =  "livecourseclassdate"
        list = { LiveCourseClassDateList }
        options = {{
          label: "Minhas Aulas"
        }}
      />

      <Resource
        icon = { LiveCourseClassDateReserveIcon }
        name =  "livecourseclassdatereserve"
        list = { LiveCourseClassDateReserveList }
        options = {{
          label: "Alunos"
        }}
      />

      <Resource
        icon = { SingleClassDateIcon }
        name =  "singleclassdate"
        list = { SingleClassDateList }
        options = {{
          label: "Minhas Aulas"
        }}
      />

      <Resource
        icon = { SingleClassDateReserveIcon }
        name =  "singleclassdatereserve"
        list = { SingleClassDateReserveList }
        options = {{
          label: "Alunos"
        }}
      />
  </Admin>
);

export default App;
