import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = classes => {
    const data = classes.map(singleClass => ({
        inicio: singleClass.init_at_format,
        termino: singleClass.end_at_format,
        aula: singleClass.name,
        numero_participantes: singleClass.number_participants,
        numero_reservas: singleClass.count_reserves
    }));
    const csv = convertToCSV({
        data,
        fields: ['inicio', 'termino', 'aula', 'numero_participantes', 'numero_reservas']
    });
    downloadCSV(csv, 'single_class_dates');
}

export default exporter;
