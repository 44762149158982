import React from 'react';
import { Link } from 'react-admin';

import { withStyles } from '@material-ui/core/styles';

const styles = {
    link: {
      color: 'blue',
      textDecoration: 'underline'
    }
};

const LinkReservesField = ({ classes, record, source, ...props }) => {
    return (
      <Link
        classes={classes}
        to={{
          pathname: `/livecourseclassdatereserve`,
          search: `filter=%7B"live_course_class_date_id"%3A${record.id}%7D&order=ASC&page=1&perPage=10&sort=profile.name`,
        }}>
        {record.count_reserves}
      </Link>
    );
};

LinkReservesField.defaultProps = {
    addLabel: true,
    source: 'Número de Reservas'
};

export default withStyles(styles)(LinkReservesField);
