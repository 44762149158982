import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import config from '../config';

var jwt = require('jwt-simple');

const ENV = process.env.NODE_ENV || 'development';
const apiUrl = config[ENV].api.url;
const apikey = config[ENV].api.api_key;
const jwtKey = config[ENV].jwt.key;
const localStorageUser = config[ENV].local_storage.user;

const authProvider = (type, params) =>
{
  // called when the user attempts to log in
  if (type === AUTH_LOGIN)
  {
    const { email, password } = params;

    const request = new Request(apiUrl + '/teacher/sign?apikey=' + apikey,
    {
      method: 'POST',
      body: JSON.stringify({ email: email, password: password}),
      headers: new Headers({'Content-Type': 'application/json'}),
    })

    return fetch(request).then(response =>
           {
              if (response.status === 400)
                return Promise.reject();

              return response.json();
            })
            .then(data => {
              localStorage.setItem(jwt.encode(localStorageUser, jwtKey), jwt.encode(JSON.stringify(data.result), jwtKey));
              return Promise.resolve();
            });
  }
  // called when the user clicks on the logout button
  else if (type === AUTH_LOGOUT)
  {
    localStorage.removeItem(jwt.encode(localStorageUser, jwtKey));
    return Promise.resolve();
  }
  // called when the API returns an error
  else if (type === AUTH_ERROR)
  {
    if (params.status === 401 || params.status === 403)
    {
      localStorage.removeItem(jwt.encode(localStorageUser, jwtKey));
      return Promise.reject();
    }

    return Promise.resolve();
  }
  // called when the user navigates to a new location
  else if (type === AUTH_CHECK)
  {
    var item = localStorage.getItem(jwt.encode(localStorageUser, jwtKey));
    if (item !== null)
    {
      return jwt.decode(item, jwtKey)
        ? Promise.resolve()
        : Promise.reject();
    }
    else
      Promise.reject();
  }
  else if (type === AUTH_GET_PERMISSIONS)
  {
    const userkey = localStorage.getItem(jwt.encode(localStorageUser, jwtKey));
    if (userkey !== null && userkey !== undefined)
    {
      const roles = JSON.parse(jwt.decode(userkey, jwtKey)).roles;
      return roles ? Promise.resolve(roles) : Promise.reject();
    }
    else
      Promise.reject();
  }
  return Promise.reject('Unknown method');
};

export default authProvider;
