import React from 'react';
import {
    List,
    Datagrid,
    TextField
} from 'react-admin';

// own ui
import CustomFilterText from '../../ui/custom-filter-text';
import SingleClassDateExporter from '../../exporter/single-class-date-exporter';
import LinkField from '../../ui/link-field';
import LinkReservesSingleClassDateField from '../../ui/link-reserves-single-class-date';

export default props => (
    <List title="Minhas Aulas" {...props} sort={{ field: 'scd.init_at', order: 'ASC' }} filters={<CustomFilterText />} bulkActionButtons={false} exporter={SingleClassDateExporter}>
      <Datagrid>
        <TextField source="id" sortable={false} />
        <TextField source="init_at_format" label="Início" sortable={false}/>
        <TextField source="end_at_format" label="Término" sortable={false}/>
        <TextField source="single_class.name" label="Aula" sortable={false}/>
        <LinkField source="single_class.single_class_plan.secure_url_file" text="Ver" label="Plano de Aula" sortable={false}/>
        <LinkField source="link" label="Link" text="Ir" sortable={false}/>
        <TextField source="number_participants" label="Número de Participantes" sortable={false}/>
        <LinkReservesSingleClassDateField label="Número de Reservas" sortable={false}/>
      </Datagrid>
    </List>
);
