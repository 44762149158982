import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';

const rowReserveStyle = () => (record, index, defaultStyle = {}) => {
    let style = defaultStyle;
    if (record.status_reserve_class_date === 0 ||
        record.status_reserve_single_class_date === 0)
        return {
            ...style,
            borderLeftColor: orange[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (record.status_reserve_class_date === 1 ||
        record.status_reserve_single_class_date === 1)
        return {
            ...style,
            borderLeftColor: green[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (record.status_reserve_class_date === 2 ||
        record.status_reserve_single_class_date === 2)
        return {
            ...style,
            borderLeftColor: red[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    if (record.status_reserve_class_date === 3 ||
        record.status_reserve_single_class_date === 3)
        return {
            ...style,
            borderLeftColor: blue[500],
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        };
    return style;
};

export default rowReserveStyle;
