import {
    downloadCSV
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = classes => {
    const data = classes.map(liveCourseClass => ({
        inicio: liveCourseClass.init_at_format,
        termino: liveCourseClass.end_at_format,
        aula: liveCourseClass.live_course_class.name,
        numero_participantes: liveCourseClass.number_participants,
        numero_reservas: liveCourseClass.count_reserves
    }));
    const csv = convertToCSV({
        data,
        fields: ['inicio', 'termino', 'aula', 'numero_participantes', 'numero_reservas']
    });
    downloadCSV(csv, 'live_course_classes');
}

export default exporter;
