import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

export default () => (
  <Card>
    <CardHeader title="Olá Administrador!" />
    <CardContent>
      <Typography>
        Qualquer dúvida sobre como administrar ou algo que queira desenvolver, entre em contato com a gente pelo e-mail <a href="mailto:contato@physis.io?subject=98 Live - Admin Dúvidas">contato@physis.io</a>
      </Typography>
    </CardContent>
  </Card>
);
